// Import the functions you need from the SDKs you need
import { getStorage, ref } from "firebase/storage"

import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    onAuthStateChanged,
    signOut,
    updatePassword,
    sendPasswordResetEmail,
    deleteUser,
    updateProfile
} from 'firebase/auth'

import { getFunctions, httpsCallable } from 'firebase/functions'

import {
    getFirestore,
    collection,
    getDoc,
    getDocs,
    setDoc,
    addDoc,
    doc,
    deleteDoc,
    query,
    where,
    orderBy,
    serverTimestamp,
    onSnapshot,
    limit,
    arrayUnion,
    arrayRemove,
    startAfter,
    startAt,
    endAt
} from 'firebase/firestore'

import { initializeApp } from 'firebase/app'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAr_P2ZKfN870Uo-Wan3PnoCRIO2PUjcI8",
  authDomain: "bleppy-13268.firebaseapp.com",
  databaseURL: "https://bleppy-13268-default-rtdb.firebaseio.com",
  projectId: "bleppy-13268",
  storageBucket: "bleppy-13268.appspot.com",
  messagingSenderId: "975534387651",
  appId: "1:975534387651:web:c18b4ebe35b68332348d76",
  measurementId: "G-2YPMLHJB1J"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

export {
    deleteUser,
    ref,
    updatePassword,
    sendPasswordResetEmail,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    onAuthStateChanged,
    signOut,
    getDoc,
    getDocs,
    setDoc,
    addDoc,
    doc,
    deleteDoc,
    query,
    where,
    orderBy,
    serverTimestamp,
    onSnapshot,
    collection,
    limit,
    httpsCallable,
    arrayUnion,
    arrayRemove,
    startAfter,
    startAt,
    endAt,
    updateProfile
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// FUNCTIONS
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const functions = getFunctions(app)


// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// DATABASE
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const db = getFirestore(app)

export const firebaseTimestamp = serverTimestamp()

export const chatsCollection = collection(db, "chats")
export const sheltersCollection = collection(db, "shelters")
export const postsCollection = collection(db, "posts")
export const petsCollection = collection(db, "pets")
export const promotionsCollection = collection(db, "promotions")
export const feedbackCollection = collection(db, "feedbacks")
export const abuseReportsCollection = collection(db, "abuseReports")
export const claimedSheltersCollection = collection(db, "claimedShelters")
export const plansCollection = collection(db, "plans")
export const deletedAccountsCollection = collection(db, "deletedAccounts")
export const glitchReportsCollection = collection(db, "glitchReports")
export const tutorialsCollection = collection(db, "tutorials")

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// AUTH
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const auth = getAuth(app)

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// STORAGE
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const storage = getStorage(app)