import { configureStore } from "@reduxjs/toolkit"

import accountReducers from "./slices/accountSlice"
import addressReducers from "./slices/addressSlice"
import allPetsReducers from "./slices/allPetsSlice"
import authReducers from "./slices/authSlice"
import cacheReducers from "./slices/cacheSlice"
import notificationReducers from "./slices/notificationSlice"
import organizationReducers from "./slices/organizationSlice"
import singlePetReducers from "./slices/singlePetSlice"
import uiReducers from "./slices/uiSlice"
import filterReducers from "./slices/filterSlice"
import fundraiserReducers from "./slices/fundraiserSlice"

const store = configureStore({
    reducer: {
        auth: authReducers,
        address: addressReducers,
        cache: cacheReducers,
        account: accountReducers,
        notification: notificationReducers,
        ui: uiReducers,
        singlePet: singlePetReducers,
        organization: organizationReducers,
        allPets: allPetsReducers,
        filter: filterReducers,
        fundraiser: fundraiserReducers
    }
})

export default store