import { createSlice } from "@reduxjs/toolkit"

import getFixedURL from "../../utils/text/getFixedURL"
import { addressActions } from "./addressSlice"

const initialState = {
    allShelters: [],
    id: "",
    name: "",
    username: "",
    website: "",
    adoptionLink: "",
    fosterLink: "",
    fosterInfo: "",
    adoptInfo: "",
    email: "",
    photo: {},
    phone: "",
    createdBy: "",
    isSheltersUsernameValid: null,
    wasProfileUpdated: false,
    shelterError: "",
    raiserAmount: {},
    isPublic: true,
    monthlyGoal: "0",
    reason: [],
    otherDeletionReason: "",
    allMultiSelectorValues: [],
    licenseExpirationDate: {}
}

const organizationSlice = createSlice({
    name: "organization",
    initialState,
    reducers: {
        onOrganizationCreation() {
            return initialState
        },
        onOrganizationDeletion() {
            return initialState
        },
        updateOrganizationLocally(state, action) {

            const value = action.payload.value
            const prop = action.payload.prop

            const isURL = prop === "website" || prop === "adoptionLink" || prop === "fosterLink"

            const updatedValue = isURL ? getFixedURL(value) : value

            // IF PROP IS PHOTO, UPDATE WILL BE AUTOMATICALLY DONE ON FIREBASE
            // DO NOT CHANGE THIS VALUE FOR "TRUE"!
            state.wasProfileUpdated = prop !== "photo"

            state[prop] = updatedValue
        },
        updateMultipleValues(state, action) {

            state.wasProfileUpdated = true

            // CREATE A COPY OF THE ARRAY WITH ALL SELECTED ITEMS
            // RIGHT NOW THIS ARRAY ONLY HAS REASON (TO DELETE) ITEMS
            // IN THE FUTURE, IT MAY HAVE OTHER KINDS OF VALUES
            const updatedSelectedItems = [...state.allMultiSelectorValues]

            // CREATE A COPY OF SPECIFIC PROP. e.g. REASON
            const updatedProp = [...state[action.payload.prop]]

            // FIND THE INDEX OF VALUE INSIDE THE ARRAY allMultiSelectorValues
            let existingIndexGlobal = updatedSelectedItems.findIndex(item => item === action.payload.value)

            // FIND THE INDEX OF VALUE INSIDE SPECIFIC PROP. e.g. REASON
            let existingIndexInProp = updatedProp.findIndex(item => item === action.payload.value)

            // IF ITEM IS ALREADY INSIDE allMultiSelectorValues ARRAY, DELETE ITEM
            if (existingIndexGlobal >= 0) {
                updatedSelectedItems.splice(existingIndexGlobal, 1)
                updatedProp.splice(existingIndexInProp, 1)

                state.allMultiSelectorValues = updatedSelectedItems
                state[action.payload.prop] = updatedProp
            }

            // OTHERWISE, ADD ITEM
            else {
                state[action.payload.prop] = updatedProp.concat(action.payload.value)
                state.allMultiSelectorValues = updatedSelectedItems.concat(action.payload.value)
            }
        },
        selectOrganization(state, action) {

            let updatedWebsite = action?.payload?.website
            updatedWebsite = getFixedURL(updatedWebsite)

            state.id = action?.payload?.id
            state.name = action?.payload?.name
            state.email = action?.payload?.email
            state.username = action?.payload?.username
            state.website = updatedWebsite
            state.photo = action?.payload?.photo
            state.phone = action?.payload?.phone
            state.createdBy = action?.payload?.createdBy
            state.raiserAmount = action?.payload?.raiserAmount
            state.monthlyGoal = action?.payload?.monthlyGoal
            state.isPublic = action?.payload?.isPublic
            state.adoptionLink = action?.payload?.adoptionLink
            state.fosterLink = action?.payload?.fosterLink
            state.fosterInfo = action?.payload?.fosterInfo
            state.adoptInfo = action?.payload?.adoptInfo
            state.licenseExpirationDate = action?.payload?.licenseExpirationDate
        },
        cleanOrganizationReducer() {
            return initialState
        }
    },
    extraReducers: builder => {
        builder
            .addCase(addressActions.updateAddress, (state) => {
                state.wasProfileUpdated = true
            })
    }
})

export const organizationActions = organizationSlice.actions

const organizationReducers = organizationSlice.reducer

export default organizationReducers