import { createSlice } from "@reduxjs/toolkit"

const initialState = {

    receiver: {},
    type: "",

    recurrence: "single",
    amount: 0,
    amountInCents: 0,

    fee: 2, // DELETE THIS VALUE
}

const fundraiserSlice = createSlice({
    name: "fundraiser",
    initialState,
    reducers: {
        updateSlice(state, action) {

            const value = action.payload.value
            const prop = action.payload.prop

            // THESE TWO PROPS WILL BE HANDLED BY THE CACHE SLICE
            if (prop === "acceptedDonation" || prop === "acceptedFee") return

            // UPDATE VALUE
            state[prop] = value

            // ADD EXTRA UPDATES
            if (prop === "amount") {
                state.amountInCents = value * 100
            }
        },
        setGiftReceiver(state, action) {

            const value = action.payload

            const isPet = !!value?.createdBy?.id

            state.receiver = value
            state.type = isPet ? "pet" : "shelter"
            state.amount = 0
            state.amountInCents = 0
            //state.fee = 0 WHEN DELETING 2 FROM THE HARD CODED INITIAL STATE, REACTIVE THIS LINE

        }
    }
})

export const fundraiserActions = fundraiserSlice.actions

const fundraiserReducers = fundraiserSlice.reducer

export default fundraiserReducers