import { auth, db, doc, signInWithEmailAndPassword, getDoc, onAuthStateChanged, httpsCallable, signOut, functions, sendPasswordResetEmail } from "../../firebase"
import { authActions } from "../slices/authSlice"
import { uiActions } from '../slices/uiSlice'
import { notificationActions } from "../slices/notificationSlice"
import { blockTimeInSeconds, flagLimit, limitOfLoginAttemptsPerSession } from '../../constants/variables'
import { cacheActions } from "../slices/cacheSlice"

import showErrorMessage from "../../utils/alerts/showErrorMessage"
import canUserLogin from "../../utils/auth/canUserLogin"
import deleteCredentialsFromStorage from "../../utils/auth/deleteCredentialsFromStorage"
import getCredentialsFromStorage from "../../utils/auth/getCredentialsFromStorage"
import saveCredentialsToStorage from "../../utils/auth/saveCredentialsToStorage"
import getSerializableDates from "../../utils/date/getSerializableDates"
import blockDevice from "../../utils/auth/blockDevice"

// THIS VARIABLE WILL MAKE SURE THAT getUserData ONLY RUNS ONCE WHEN USER LOGS IN
// WITHOUT THIS VARIABLE, EVERY TIME THE LISTENER onAuthStateChanged TRIGGERS,
// IT CALLS getUserData UNNECESSARILY 
let callCount = 0

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Creating an account 
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const createAnAccount = (userData) => {

    return async (dispatch) => {

        // SHOW SPINNER
        dispatch(uiActions.startSpinner())

        // CLEAN ANY PREVIOUS ERRORS FROM AUTH REDUCER
        dispatch(notificationActions.cleanError())

        try {

            // CREATE USER ON FIREBASE AUTHENTICATION AND FIRESTORE
            const createUserProfile = httpsCallable(functions, "createUserProfile")
            await createUserProfile({ userData })

            // SAVE DATA TO LOCAL STORAGE
            const { email, password } = userData

            // UPDATE REDUCER
            dispatch(loginUser({ password, email }))

        }

        // SHOW TOAST WITH ERROR MESSAGE
        catch (error) {
            showErrorMessage(error?.message)
            dispatch(uiActions.stopSpinner())
        }

    }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Logout
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const logUserOut = () => {

    return async (dispatch) => {

        callCount = 0

        try {

            // SHOW SPINNER
            dispatch(uiActions.startSpinner())

            // DELETE SAVED DATA FROM COMPUTER'S MEMORY
            deleteCredentialsFromStorage()

            // LOG OUT FROM FIREBASE
            await signOut(auth)

            // UPDATE UI
            dispatch(authActions.userLogout())

        } catch (error) {
            showErrorMessage(error.message)
        }
    }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Login
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const loginUser = ({ email, password }) => {
    return async (dispatch, getState) => {

        let user
        let userId

        const loginAttempt = getState().auth.loginAttempt

        // - - - - - - - - - - - - - - - - - - - - -
        // LOG IN USER TO APP
        // - - - - - - - - - - - - - - - - - - - - -
        try {

            // IF USER IS TEMPORARY BLOCKED, RETURN
            const canContinue = canUserLogin({ loginAttempt, email })

            if (!canContinue) return

            // SHOW SPINNER
            dispatch(uiActions.startSpinner())

            // LOG IN USER
            user = await signInWithEmailAndPassword(auth, email, password)

            // EXTRACT DATA FROM AUTHENTICATED USER
            const { stsTokenManager, uid } = user.user

            const expirationTime = stsTokenManager.expirationTime
            userId = uid
            const authToken = await user.user.getIdToken()

            // SET TIMER TO REAUTHENTICATE WHEN TOKEN EXPIRES
            setTimeout(() => {
                dispatch(authenticate())
            }, expirationTime)

            // SAVE DATA FOR RE-AUTHENTICATION WHEN USER REOPENS THE APP
            saveCredentialsToStorage({ email, password, expirationTime, authToken })

        } catch (error) {

            const chances = limitOfLoginAttemptsPerSession - loginAttempt

            const blockedMessage = "Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests)."

            const notFoundMessage = "Firebase: Error (auth/user-not-found)."

            if (error.message === blockedMessage || chances === 0) {
                blockDevice({ email, dispatch })
            }

            else if (error.message === notFoundMessage) {
                let message = "No account was found for this email address."

                dispatch(authActions.loginFail(message))
            }

            else if (chances > 0) {
                let message = `This password doesn't seem to match this account. You only have ${chances} more change${chances > 1 ? "s" : ""}.`

                dispatch(authActions.loginFail(message))
            }

            return false
        }

        // - - - - - - - - - - - - - - - - - - - - -
        // GET USER PUBLIC AND PRIVATE DATA FROM FIRESTORE
        // - - - - - - - - - - - - - - - - - - - - -

        try {

            dispatch(getUserData(userId))

            return userId

        }

        // IF UNABLE TO LOAD USER'S DATA, LOG OUT USER
        catch (error) {

            showErrorMessage(error.message)
            dispatch(logUserOut())

            return false
        }
    }
}


// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// AUTHENTICATE OR REAUTHENTICATE
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const authenticate = () => {

    return async dispatch => {

        try {

            const changesHandler = async (currentUser) => {

                // GET USER'S CREDENTIALS FROM STORAGE
                const { email, password, expirationTime } = getCredentialsFromStorage()

                // CHECK FOR CREDENTIAL'S AVAILABILITY
                const hasCredentials = !!email && !!password && !!expirationTime

                // REAUTHENTICATE USER IF TOKEN WILL EXPIRE IN LESS THAN blockTimeInSeconds
                const tokenIsAboutToExpire = !!expirationTime && (expirationTime - Date.now()) < blockTimeInSeconds

                if (hasCredentials && (!currentUser || tokenIsAboutToExpire)) {

                    // OTHERWISE, LOG IN USER
                    await dispatch(loginUser({ email, password }))

                    return
                }

                // IF USER IS LOGGED IN, GET THEIR DATA AND UPDATE REDUCER
                else if (!!currentUser) {

                    // SHOW SPINNER
                    dispatch(uiActions.startSpinner())

                    // GET USER'S DATA
                    const userId = currentUser.uid
                    dispatch(getUserData(userId))

                }

            }

            onAuthStateChanged(auth, changesHandler)

        } catch (error) {
            showErrorMessage(error.message)
        }
    }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// GET USER'S PUBLIC AND PRIVATE DATA FROM FIRESTORE
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const getUserData = (userId, isStripe) => {

    return async (dispatch) => {

        if (callCount > 0 && !isStripe) return
        callCount++

        // GET PRIVATE DATA
        const privateDataRef = doc(db, `users/${userId}/privateData/${userId}`)
        const privateDataSnapshot = await getDoc(privateDataRef)
        const userPrivateData = privateDataSnapshot.data()

        // GET PUBLIC DATA
        const userRef = doc(db, "users", userId)
        const publicDataSnapshot = await getDoc(userRef)
        let userPublicData = publicDataSnapshot.data()

        // NON-SERIALIZABLE VALUES RETURNS A ERROR WITH REDUX TOOLKIT
        // TRANSFORM VALUES BEFORE STORING THEM ON THE REDUCER, TO AVOID THAT
        const { createdAt } = getSerializableDates(publicDataSnapshot)

        // CREATE USER OBJECT
        const user = {
            ...userPublicData,
            ...userPrivateData,
            id: userId,
            createdAt
        }

        delete user.flags

        dispatch(authActions.loginUserSuccess(user))

        // IF USER REACHED FLAG LIMIT, LOG USER OUT
        const { flagged } = userPrivateData

        if (flagged === flagLimit) {
            dispatch(notificationActions.showReviewAccountModal())
        }

        else {

            dispatch(saveUserActivity(userId))
        }
    }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// SAVE USER ACTIVITY 
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const saveUserActivity = (userId) => {
    return async (dispatch, getState) => {

        const savedActivity = getState().cache.savedActivity

        if (!savedActivity && !!userId) {

            try {
                const saveActivity = httpsCallable(functions, "saveUserActivity")
                await saveActivity({ userId })

                dispatch(cacheActions.setCache({ prop: "savedActivity", value: true }))

            } catch (error) {

            }
        }
    }
}


// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// RESET PASSWORD - FORGOT PASSWORD
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const sendResetPassEmail = ({ email }) => {
    return async (dispatch) => {

        try {

            const notification = `A link was sent to ${email}. Please verify your inbox and follow the link to reset your password.`

            await sendPasswordResetEmail(auth, email)

            dispatch(notificationActions.setTopNotification(notification))

            return true

        } catch (error) {

            let message = error.message

            if (message === "Firebase: Error (auth/user-not-found).") {
                message = "No matching accounts were found for this email - sorry about that!"
            }

            showErrorMessage(message)
            return false
        }
    }
}