import { createSlice } from "@reduxjs/toolkit"
import { accountActions } from "./accountSlice"

const initialState = {

    optionsForFilteringPets: [],

    filteredPets: [],

    selectedPet: {},
    petNotFound: false,

    hasLoadedFavorites: false,
    favoritePets: [],

    filteredOrganizations: [],
    selectedOrganization: {},
    organizationNotFound: false,

    selectedUser: {},
    userNotFound: false,

}

const filterSlice = createSlice({
    name: "filter",
    initialState,
    reducers: {
        setFilteredPets(state, action) {

            const shouldUpdate = action.payload.page === "organizationProfile" || action.payload.page === "userProfile"

            if (shouldUpdate) {
                state.filteredPets = action.payload.filteredPets
            }

            else return initialState
        },
        setAdvancedFilteredPets(state, action) {
            state.filteredPets = action.payload.filteredPets
        },
        setFavoritePets(state, action) {
            state.favoritePets = action.payload
            state.hasLoadedFavorites = true
        },

        setFilteredOrganizations(state, action) {
            state.filteredOrganizations = action.payload.organizations
        },
        selectPet(state, action) {
            state.selectedPet = action.payload
            state.petNotFound = false
        },
        selectUser(state, action) {
            state.selectedUser = action.payload
            state.userNotFound = false
        },
        selectOrganization(state, action) {
            state.selectedOrganization = action.payload
            state.organizationNotFound = false
        },
        markAsNotFound(state, action) {
            state[action.payload] = true
        },
        resetFilter() {
            return initialState
        },
        setOptionsForFilter(state, action) {

            const shouldDeleteFromArray = action.payload.value === ""

            // COPY O FILTER'S ARRAY STATE
            let updatedOptionsForFilter = [...state.optionsForFilteringPets]

            const position = action.payload.prop

            // FIND ITS INDEX
            const existingIndex = updatedOptionsForFilter.findIndex(item => item[position])

            // ITEM TO REPLACE PREVIOUS SELECTION
            const newOption = { [action.payload.prop]: action.payload.value }

            // IF ITEM EXISTS INSIDE ARRAY, REPLACE IT
            if (shouldDeleteFromArray) {
                updatedOptionsForFilter.splice(existingIndex, 1)
            }
            else if (existingIndex >= 0) {
                updatedOptionsForFilter.splice(existingIndex, 1, newOption)

                // OTHERWISE, ADD IT TO ARRAY
            } else {
                updatedOptionsForFilter.push(newOption)
            }

            state.optionsForFilteringPets = updatedOptionsForFilter

        },
        cleanOptionsForFilter(state) {
            state.optionsForFilteringPets = []
            state.filteredPets = []
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(accountActions.toggleFollow, (state, action) => {

                if (action.payload.isFollowing && state.selectedUser.numOfFollowers > 0) {
                    state.selectedUser.numOfFollowers--
                }

                else if (!action.payload.isFollowing) {
                    state.selectedUser.numOfFollowers++
                }
            })
            .addCase(accountActions.toggleFavorite, (state, action) => {

                const existingIndex = state.favoritePets.findIndex(pet => pet.id === action.payload.id)

                if (existingIndex >= 0) {
                    state.favoritePets.splice(existingIndex, 1)
                }

                else {
                    state.favoritePets.push(action.payload)
                }

            })
    }
})

export const filterActions = filterSlice.actions

const filterReducers = filterSlice.reducer

export default filterReducers