const getFixedURL = (value) => {

    if (!value.includes(".")) return value

    const rightPrefix = "https://www."
    const onlyHTTPS = "https://"
    const httpPrefix = "http://www."
    const onlyHTTP = "http://"
    const onlyWWW = "www."

    let updatedValue = value.includes(rightPrefix) ? value :
        value.includes(onlyHTTPS) ? value.replace(onlyHTTPS, rightPrefix) :
            value.includes(httpPrefix) ? value.replace(httpPrefix, rightPrefix) :
                value.includes(onlyHTTP) ? value.replace(onlyHTTP, rightPrefix) :
                    value.includes(onlyWWW) ? value.replace(onlyWWW, rightPrefix) :
                        `${rightPrefix}${value}`

    // IF WEBSITE WASN'T PROVIDED, NO NEED TO RETURN THE PREFIX
    if (updatedValue === "https://www.") return ""

    return updatedValue
}

export default getFixedURL

