//import Colors from "./Colors"
//import { isTablet, phoneHasExtraSmallWidth, screenWidth } from "./ScreenInfo"

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// FEED
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// IF COMING BACK TO FEED SCREEN IN LESS THAN 
// 5 MINUTES AFTER LEAVING IT, DO NOT RELOAD FEED
// 5 MINUTES = 300,000 MILLISECONDS
export const limitTimeToAvoidFeedRefresh = 300000

// HOW MANY PETS SHOULD RENDER AT FEED SCREEN BEFORE RENDER 1 POST
export const limitOfRenderedPetsPerCicle = 5

// HOW MANY PETS SHOULD BE LOAD EACH TIME ON FEED
export const limitPetsPerFeedLoad = 200

// HOW MANY PETS SHOULD BE LOAD EACH TIME ON ADOPT/MISSING
//export const limitPetsPerLoad = 24 // isTablet ? 20 : 15

// HOW MANY POSTS SHOULD LOAD EACH TIME ON FEED
export const limitPostsPerLoad = limitPetsPerFeedLoad / limitOfRenderedPetsPerCicle

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// USERS
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const flagLimit = 10
export const limitTimeSpanForReport = 300000 // 5 minutes
export const limitOfLoginAttemptsPerSession = 3
export const maxPetsForGuardianProfile = 6
export const blockTimeInSeconds = 300000 // 300000 = 5 minutes

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// DONATIONS
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const defaultDonationsLimit = 1000 * 100 // AMOUNT IN CENTS
export const taxes = +0
export const giftColor = "white"
//export const giftBackground = { backgroundColor: Colors.primaryColor }
export const maximumDonationsWhithoutVerification = 100

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// MARGINS AND PADDINGS
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const marginBetweenComponents = 20
export const marginBetweenText = "1.5%"
export const paddingHorizontal = 10
export const containerWidth = "90%"
//export const inputContainerPadding = phoneHasExtraSmallWidth ? "3%" : "2%"
export const letterSpacing = 2

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// FONTS
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const specialFont = "luckiestGuy-regular"
export const defaultLight = "openSans-light"
export const defaultRegular = "openSans-regular"
export const defaultBold = "openSans-bold"
export const defaultSemiBold = "openSans-semiBold"

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// UI
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// CARD FOR ADOPTION AND MISSING TABS
//export const cardHeight = phoneHasExtraSmallWidth ? screenWidth / 1.6 : isTablet ? screenWidth / 2.6 : screenWidth / 1.8

// LIMIT OF CHARACTERS ON MULTLINE INPUT TEXT
export const limitOfCharacters = 1000

export const limitImageSize = 1024 // 1 MB