import { createSlice } from "@reduxjs/toolkit"
import { accountActions } from "./accountSlice"
import { allPetsActions } from "./allPetsSlice"
import { authActions } from "./authSlice"
import { notificationActions } from "./notificationSlice"
import { organizationActions } from "./organizationSlice"
import { singlePetActions } from "./singlePetSlice"
import { filterActions } from "./filterSlice"

const initialState = {
    isLoading: false,
    lastDeletedItem: "",
    isVerifyingUsername: false,
    showMenuIcon: true
}

const uiSlice = createSlice({
    name: "ui",
    initialState,
    reducers: {
        startSpinner: (state) => {
            state.isLoading = true
        },
        stopSpinner: (state) => {
            state.isLoading = false
        },
        verifyingUsername: (state, action) => {
            state.isVerifyingUsername = action.payload
        },
        setMobileMenu: (state, action) => {
            state.showMenuIcon = action?.payload || !state.showMenuIcon
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(authActions.loginUserSuccess, (state) => {
                state.isLoading = false
            })
            .addCase(authActions.loginFail, (state) => {
                state.isLoading = false
            })
            .addCase(authActions.userLogout, (state) => {
                state.isLoading = false
            })
            .addCase(notificationActions.setTopNotification, (state) => {
                state.isLoading = false
            })
            .addCase(accountActions.updateUsersProfile, (state) => {
                state.isLoading = false
            })
            .addCase(accountActions.setUsersPets, (state) => {
                state.isLoading = false
            })
            .addCase(filterActions.markAsNotFound, (state) => {
                state.isLoading = false
            })
            .addCase(filterActions.setFavoritePets, (state) => {
                state.isLoading = false
            })
            .addCase(filterActions.setFilteredPets, (state) => {
                state.isLoading = false
            })
            .addCase(filterActions.setAdvancedFilteredPets, (state) => {
                state.isLoading = false
            })
            .addCase(filterActions.setFilteredOrganizations, (state) => {
                state.isLoading = false
            })
            .addCase(filterActions.selectOrganization, (state) => {
                state.isLoading = false
            })
            .addCase(filterActions.selectPet, (state) => {
                state.isLoading = false
            })
            .addCase(organizationActions.onOrganizationCreation, (state) => {
                state.isLoading = false
            })
            .addCase(organizationActions.onOrganizationDeletion, (state, action) => {
                state.isLoading = false
                state.lastDeletedItem = action.payload
            })
            .addCase(organizationActions.cleanOrganizationReducer, () => {
                return initialState
            })
            .addCase(allPetsActions.setHomePagePets, () => {
                return initialState
            })
            .addCase(singlePetActions.savePetProfile, () => {
                return initialState
            })
            .addCase(singlePetActions.onPetDeletion, (state, action) => {
                state.isLoading = false
                state.lastDeletedItem = action.payload
            })
            .addCase(organizationActions.updateOrganizationLocally, (state, action) => {

                if (action.payload.prop === "username")
                    state.isVerifyingUsername = true
            })
    }
})

const uiReducers = uiSlice.reducer

export const uiActions = uiSlice.actions

export default uiReducers