import { createSlice } from "@reduxjs/toolkit"
import { authActions } from "./authSlice"
import { organizationActions } from "./organizationSlice"
import { singlePetActions } from "./singlePetSlice"

const initialState = {
    address: "",
    suite: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
    latitude: 0,
    longitude: 0,
    //locationPickerValue: -1, DO WE NEED THIS PROP ON THE WEB?
    isValid: false,
    wasAddressUpdated: false
}

const addressSlice = createSlice({
    name: "address",
    initialState,
    reducers: {

        // UPDATE DATA LOCALLY
        updateAddress(state, action) {
            state[action.payload.prop] = action.payload.value
            state.wasAddressUpdated = true
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(authActions.userLogout, () => {
                return initialState
            })
            .addCase(organizationActions.selectOrganization, (state, action) => {
                state.address = action?.payload?.address?.address || ""
                state.suite = action?.payload?.address?.suite || ""
                state.city = action?.payload?.address?.city || ""
                state.state = action?.payload?.address?.state || ""
                state.country = action?.payload?.address?.country || ""
                state.zipcode = action?.payload?.address?.zipcode || ""
                state.latitude = action?.payload?.address?.latitude || 0
                state.longitude = action?.payload?.address?.longitude || 0
                state.locationPickerValue = -1
                state.isValid = true
            })
            .addCase(organizationActions.cleanOrganizationReducer, () => {
                return initialState
            })
            .addCase(singlePetActions.updatePetInfo, (state, action) => {

                if (action.payload?.prop === "guardian") {

                    const petsAddress = action?.payload?.value?.address

                    state.address = petsAddress?.address || ""
                    state.suite = petsAddress?.suite || ""
                    state.city = petsAddress?.city || ""
                    state.state = petsAddress?.state || ""
                    state.country = petsAddress?.country || ""
                    state.zipcode = petsAddress?.zipcode || ""
                    state.latitude = petsAddress?.latitude || 0
                    state.longitude = petsAddress?.longitude || 0
                    //state.locationPickerValue = -1
                    state.isValid = true

                }
            })
            .addCase(singlePetActions.cleanSinglePetReducer, () => {
                return initialState
            })
            .addCase(singlePetActions.selectPet, (state, action) => {

                state.address = action?.payload?.address?.address || ""
                state.suite = action?.payload?.address?.suite || ""
                state.city = action?.payload?.address?.city || ""
                state.state = action?.payload?.address?.state || ""
                state.country = action?.payload?.address?.country || ""
                state.zipcode = action?.payload?.address?.zipcode || ""
                state.latitude = action?.payload?.address?.latitude || 0
                state.longitude = action?.payload?.address?.longitude || 0
                state.locationPickerValue = -1
                state.isValid = true

            })
    }
})

export const addressActions = addressSlice.actions

const addressReducers = addressSlice.reducer

export default addressReducers