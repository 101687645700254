import getDataFromStorage from "../storage/getDataFromStorage"

const getCredentialsFromStorage = () => {

    const password = getDataFromStorage("password")
    const email = getDataFromStorage("email")
    const expirationTime = getDataFromStorage("expirationTime")
    const authToken = getDataFromStorage("authToken")

    const credentials = {
        expirationTime,
        password,
        email,
        authToken
    }

    return credentials
}

export default getCredentialsFromStorage