import { createSlice } from "@reduxjs/toolkit"
import { addressActions } from "./addressSlice"

const initialState = {
    allMultiSelectorValues: [],
    reason: [],
    otherDeletionReason: "",
    name: "",
    id: "",
    species: "",
    age: "",
    color: "",
    size: "",
    gender: "",
    character: [],
    breed: "",
    health: [],
    history: "",
    status: "",
    address: {},
    photos: [],
    guardian: {},
    //isPetInShelter: "", DO WE REALLY NEED THIS PROP ON THE WEB?
    //guardianPickerValue: -1, DO WE REALLY NEED THIS PROP ON THE WEB?
    isFormValid: false,
    wasProfileUpdated: false,
    error: "",
    expenses: {},
    blockReason: "",
    specialLimitOfDonations: 0,
    internalId: "",
    favoritedBy: 0,
    createdBy: {},
    verifiedPhoto: {},
    activity: "N/A",
    drool: "N/A",
    shedding: "N/A",
    shouldUpdatePrivateData: false,
    adoptionFee: "",
    petError: ""
}

const singlePetSlice = createSlice({
    name: "pet",
    initialState,
    reducers: {
        updatePetInfo: (state, action) => {

            const prop = action.payload?.prop
            const value = action.payload?.value

            state[prop] = value
            state.wasProfileUpdated = true

            if (prop === "guardian") {
                state.guardian = value?.name ? value : {}
                state.address = value?.address || {}
                state.shouldUpdatePrivateData = true
            }

            else if (prop === "expenses") {

                // SUM ALL EXPENSES IN THIS VARIABLE TO CONTROL RENDER ON USEEFECT
                state.expenses.total = (
                    (Number(state.expenses?.food) || 0)
                    + (Number(state.expenses?.toys) || 0)
                    + (Number(state.expenses?.vet) || 0)
                    + (Number(state.expenses?.other) || 0)
                ) * 100

            }

            // CLEAN BREED STATE AFTER SELECTING A NEW SPECIES
            else if (prop === "species") {
                state.breed = ""
            }
        },
        updateMultipleChoicePetInfo: (state, action) => {

            // CREATE A COPY OF THE ARRAY WITH ALL SELECTED ITEMS
            // THIS ARRAY MAY INCLUDE: HEALTH, CHARACTERISTICS, AND REASON (TO DELETE) ITEMS
            const updatedSelectedItems = [...state.allMultiSelectorValues]

            // CREATE A COPY OF SPECIFIC PROP. e.g. HEALTH
            const updatedProp = [...state[action.payload.prop]]

            // FIND THE INDEX OF VALUE INSIDE THE ARRAY allMultiSelectorValues
            let existingIndexGlobal = updatedSelectedItems.findIndex(item => item === action.payload.value)

            // FIND THE INDEX OF VALUE INSIDE SPECIFIC PROP. e.g. HEALTH
            let existingIndexInProp = updatedProp.findIndex(item => item === action.payload.value)

            state.wasProfileUpdated = true

            // IF ITEM IS ALREADY INSIDE allMultiSelectorValues ARRAY, DELETE ITEM
            if (existingIndexGlobal >= 0) {
                updatedSelectedItems.splice(existingIndexGlobal, 1)
                updatedProp.splice(existingIndexInProp, 1)
                state.allMultiSelectorValues = updatedSelectedItems
                state[action.payload.prop] = updatedProp
            }

            // OTHERWISE, ADD ITEM
            else {
                state[action.payload.prop] = updatedProp.concat(action.payload.value)
                state.allMultiSelectorValues = updatedSelectedItems.concat(action.payload.value)
            }

        },
        updatePetImage: (state, action) => {
            state.photos = action.payload?.image?.url ? [...state.photos, action.payload?.image] : state.photos
            state.wasProfileUpdated = true
        },
        deletePetImage: (state, action) => {
            state.photos = state.photos.filter(photo => photo.url !== action.payload.url)
        },
        cleanSinglePetReducer: () => {
            return initialState
        },
        savePetProfile: () => {
            return initialState
        },
        selectPet(state, action) {

            const updatedHealth = action?.payload?.health || []
            const updatedCharacter = action?.payload?.character || []
            const updatedComments = action?.payload?.comments || []

            const updatedMultiSelectorValues = action?.payload?.health || action?.payload?.character ? [...updatedHealth, ...updatedCharacter] : []

            state.name = action?.payload?.name
            state.id = action?.payload?.id
            state.species = action?.payload?.species
            state.age = action?.payload?.age
            state.gender = action?.payload?.gender
            state.character = updatedCharacter
            state.comments = updatedComments
            state.breed = action?.payload?.breed
            state.health = updatedHealth
            state.history = action?.payload?.history
            state.status = action?.payload?.status
            state.photos = action?.payload?.photos || []
            state.guardian = action?.payload?.guardian
            state.createdBy = action?.payload?.createdBy
            state.color = action?.payload?.color
            state.size = action?.payload?.size
            state.address = action?.payload?.address
            state.expenses = action?.payload?.expenses
            state.blockReason = action?.payload?.blockReason
            state.allMultiSelectorValues = updatedMultiSelectorValues
            state.isPublic = action?.payload?.isPublic
            state.specialLimitOfDonations = action?.payload?.specialLimitOfDonations
            state.internalId = action?.payload?.internalId
            state.favoritedBy = action?.payload?.favoritedBy
            state.verifiedPhoto = action?.payload?.verifiedPhoto
            state.activity = action?.payload?.activity
            state.drool = action?.payload?.drool
            state.shedding = action?.payload?.shedding
            state.adoptionFee = action?.payload?.adoptionFee
        },
        onPetDeletion: () => {
            return initialState
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(addressActions.updateAddress, (state, action) => {
                state.address[action.payload.prop] = action.payload.value
                state.shouldUpdatePrivateData = true
            })
    }
})

export const singlePetActions = singlePetSlice.actions

const singlePetReducers = singlePetSlice.reducer

export default singlePetReducers
