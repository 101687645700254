import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    name: "",
    username: "",
    birth: "",
    email: "",
    password: "",
    phone: "",
    photo: {},
    isUsernameValidUser: null,
    wasProfileUpdated: false,
    addedPets: 0,
    subscription: "freemium",
    acceptedTerms: false,
    oldPassword: "",
    oldPhoneNumber: "",
    createdAt: "",
    loginAttempt: 1,
    isOldEnough: false
}


const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {

        // UPDATE USER INFO LOCALLY
        updateUserInfo(state, action) {
            state.wasProfileUpdated = true
            state[action.payload?.prop] = action.payload?.value

            // IF UPDATING EMAIL AFTER LOGIN ERROR, RESET VALUE OF loginAttempt
            // SINCE USER WILL BE TRYING A NEW EMAIL
            if (action.payload?.prop === "email") {
                state.loginAttempt = 1
            }
        },

        // INCREASE LOGIN ATTEMPT WHEN IT FAILS, SO USER CAN BE BLOCKED AFTER 5 ATTEMPTS
        loginFail(state) {
            state.loginAttempt++
        },

        // UPDATE LOCALLY AFTER LOGGING IN USER
        loginUserSuccess(state, action) {
            state.name = action.payload?.name
            state.username = action.payload?.username || ""
            state.isUsernameValidUser = action.payload?.username !== "" ? true : false
            state.birth = action.payload?.birth || state.birth || ""
            state.email = action.payload?.email
            state.website = action.payload?.website || ""
            state.phone = action.payload?.phone || state.phone || ""
            state.photo = action.payload?.photo || {}
            state.addedPets = action.payload?.addedPets
            state.subscription = action.payload?.subscription || "freemium"
            state.password = ""
            state.oldPhoneNumber = action.payload?.phone || state.phone || ""
            state.acceptedTerms = true
            state.createdAt = action.payload?.createdAt || ""
            state.isOldEnough = true
        },

        // RESET STATE
        userLogout() {
            return initialState
        },
    }
})

export const authActions = authSlice.actions

const authReducers = authSlice.reducer

export default authReducers