import { functions, httpsCallable } from "../../firebase"

import { blockTimeInSeconds } from "../../constants/variables"
import { notificationActions } from "../../store/slices/notificationSlice"

import saveDataToStorage from "../storage/saveDataToStorage"

const blockDevice = async ({ email, dispatch }) => {

    // SET EXPIRATION FOR BLOCK ON LOCAL STORAGE
    const blockExpiresAt = Date.now() + blockTimeInSeconds
    saveDataToStorage({ key: "blockExpiration", value: blockExpiresAt })

    // BLOCK DEVICE 
    const blockedMessage = "This device has been blocked for 5 minutes. You can try again later."
    dispatch(notificationActions.setTopNotification(blockedMessage))

    // FLAG USER AFTER LIMIT OF ATTEMPTS IS REACHED
    // EVENTUALLY THE USER WILL REACH 10 FLAGS AND
    // THEIR ACCOUNT WILL BE SENT TO REVIEW
    const flagUserForLoginError = httpsCallable(functions, "flagUserForLoginError")
    await flagUserForLoginError({ email })

}

export default blockDevice