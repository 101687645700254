const saveDataToStorage = ({ key, value }) => {

    // STRINGFY VALUE TO MAKE SURE COMPLEX VALUES LIKE OBJECT ARE SAVED IN 
    // A WAY THAT IT'LL BE USEFUL WHEN FETCHING THAT DATA
    const string = JSON.stringify(value)

    window.localStorage.setItem(key, string)

}

export default saveDataToStorage