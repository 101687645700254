import { useEffect, useRef, lazy, Suspense } from "react"
import { useDispatch, useSelector } from "react-redux"
import { authenticate } from "./store/actions/auth"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { ToastContainer } from "react-toastify"

import "react-toastify/dist/ReactToastify.css"

const HomePage = lazy(() => import("./pages/HomePage"))
const LoginPage = lazy(() => import("./pages/auth/LoginPage"))
const ForgotPasswordPage = lazy(() => import("./pages/auth/ForgotPasswordPage"))
const SignupPage = lazy(() => import("./pages/auth/SignupPage"))
const UpdateOrganizationPage = lazy(() => import("./pages/add-update/UpdateOrganizationPage"))
const Header = lazy(() => import("./components/header/Header"))
const PrivateRoute = lazy(() => import("./components/routes/PrivateRoute"))
const DashboardPage = lazy(() => import("./pages/user/DashboardPage"))
const EditUserProfilePage = lazy(() => import("./pages/user/EditUserProfilePage"))
const UpdatePetPage = lazy(() => import("./pages/add-update/UpdatePetPage"))
const OrganizationProfilePage = lazy(() => import("./pages/organizations/OrganizationProfilePage"))
const PetProfilePage = lazy(() => import("./pages/pets/PetProfilePage"))
const BlockedAccountModal = lazy(() => import("./components/alerts/BlockedAccountModal"))
const ScrollToTop = lazy(() => import("./components/header/ScrollToTop"))
const Footer = lazy(() => import("./components/footer/Footer"))
const AddPetPage = lazy(() => import("./pages/add-update/AddPetPage"))
const FilteredPetsPage = lazy(() => import("./pages/filter/FilteredPetsPage"))
const FilteredOrganizationsPage = lazy(() => import("./pages/filter/FilteredOrganizationsPage"))
const UserProfilePage = lazy(() => import("./pages/user/UserProfilePage"))
const MyFavoritesPage = lazy(() => import("./pages/user/MyFavoritesPage"))
const CheckoutPage = lazy(() => import("./pages/fundraiser/CheckoutPage"))

function App() {

  const dispatch = useDispatch()
  let renderCount = useRef(0).current

  // REAUTHENTICATE USER IF CREDENTIALS ARE SAVED ON LOCAL STORAGE
  const { isLoggedIn } = useSelector(state => state.account)
  const { showReviewAccountModal } = useSelector(state => state.notification)

  useEffect(() => {

    // MAKE SURE THIS FUNCTION ONLY RUNS ONCE
    if (renderCount > 0) return
    renderCount++

    if (!isLoggedIn) {
      dispatch(authenticate())
    }
  }, [isLoggedIn, dispatch, renderCount])

  return (
    <div className="flex flex-col min-h-screen">
      <Router>
        <ScrollToTop />
        <Header />
        {showReviewAccountModal && <BlockedAccountModal />}

        <Routes>
          <Route path="/"
            element={<Suspense><HomePage /></Suspense>} />
          <Route path="/login"
            element={<Suspense><LoginPage /></Suspense>} />
          <Route path="/forgot-password"
            element={<Suspense><ForgotPasswordPage /></Suspense>} />
          <Route path="/signup"
            element={<Suspense><SignupPage /></Suspense>} />
          <Route path="/pet-profile/:id"
            element={<Suspense><PetProfilePage /></Suspense>} />
          <Route path="/organization-profile/:id"
            element={<Suspense><OrganizationProfilePage /></Suspense>} />
          <Route path="/user-profile/:id"
            element={<Suspense><UserProfilePage /></Suspense>} />
          <Route path="/filtered-pets/:filteredBy/:filteredValue"
            element={<Suspense><FilteredPetsPage /></Suspense>} />

          <Route path="/filtered-organizations/:filteredBy/:value"
            element={<Suspense><FilteredOrganizationsPage /></Suspense>} />
          <Route path="/filtered-organizations"
            element={<Suspense><FilteredOrganizationsPage /></Suspense>} />

          <Route path="/profile"
            element={<Suspense><PrivateRoute /></Suspense>}>
            <Route path="/profile"
              element={<Suspense><EditUserProfilePage /></Suspense>} />
          </Route>

          <Route path="/dashboard"
            element={<Suspense><PrivateRoute /></Suspense>}>
            <Route path="/dashboard"
              element={<Suspense><DashboardPage /></Suspense>} />
          </Route>

          <Route path="/checkout"
            element={<Suspense><PrivateRoute /></Suspense>}>
            <Route path="/checkout"
              element={<Suspense><CheckoutPage /></Suspense>} />
          </Route>

          <Route path="/favorites"
            element={<Suspense><PrivateRoute /></Suspense>}>
            <Route path="/favorites"
              element={<Suspense><MyFavoritesPage /></Suspense>} />
          </Route>

          <Route path="/update-pet"
            element={<Suspense><PrivateRoute /></Suspense>}>
            <Route path="/update-pet"
              element={<Suspense><UpdatePetPage /></Suspense>} />
          </Route>

          <Route path="/add-pet"
            element={<Suspense><PrivateRoute /></Suspense>}>
            <Route path="/add-pet"
              element={<Suspense><AddPetPage /></Suspense>} />
          </Route>

          <Route path="/update-organization"
            element={<Suspense><PrivateRoute /></Suspense>}>
            <Route path="/update-organization/:action"
              element={<Suspense><UpdateOrganizationPage /></Suspense>} />
          </Route>

          <Route path="*"
            element={<Suspense><HomePage /></Suspense>} />

        </Routes>
      </Router>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
      <Footer />
    </div>
  )
}

export default App
