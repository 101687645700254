import { createSlice } from "@reduxjs/toolkit"
import { addressActions } from "./addressSlice"
import { allPetsActions } from "./allPetsSlice"
import { fundraiserActions } from "./fundraiserSlice"
import { filterActions } from "./filterSlice"

const initialState = {
    lastUsedLocation: {},
    credentials: {},
    hasLocationPermission: false,
    hasCameraPermissions: false,
    pushToken: "",
    plans: [],
    lastPetCreatedAt: 0,
    tutorials: [],
    savedActivity: false,
    guardianFilteredPets: [],

    filteredByAdoption: [],
    filteredByFound: [],
    filteredByMissing: [],
    filteredByCanReceiveFunds: [],
    filteredByDog: [],
    filteredByCat: [],
    filteredByGuineaPig: [],

    stateForFilter: "",

    lastPetCreated: {},
    lastPostCreated: {},

    giftablePets: [],
    giftableOrganizations: [],

    acceptedDonation: false,
    acceptedFee: false,

    guardians: [],
}

const cacheSlice = createSlice({
    name: "cache",
    initialState,
    reducers: {

        // SET CACHE
        setCache(state, action) {

            const prop = action.payload.prop
            const value = action.payload.value

            state[prop] = prop === "stateForFilter" ? value.toUpperCase() : value

            // CLEAN ARRAY OF PETS PREVIOUSLY LOADED.
            state.filteredByAdoption = []
            state.filteredByMissing = []
            state.filteredByFound = []
            state.filteredByCanReceiveFunds = []
            state.filteredByCat = []
            state.filteredByDog = []
            state.filteredByGuineaPig = []
        }
    },

    extraReducers: (builder) => {
        builder
            .addCase(addressActions.updateAddress, (state, action) => {
                state = { ...state }
                state.lastUsedLocation[action.payload.prop] = action.payload.value
            })
            .addCase(fundraiserActions.updateSlice, (state, action) => {

                const prop = action.payload.prop

                if (prop === "acceptedDonation" || prop === "acceptedFee") {
                    state[prop] = action.payload.value
                }

            })
            .addCase(allPetsActions.setHomePagePets, (state, action) => {
                state.giftablePets = action.payload.giftablePets
            })
            .addCase(filterActions.setFilteredPets, (state, action) => {

                const filteredBy = action?.payload?.filteredBy
                const filteredValue = action?.payload?.filteredValue
                const filteredPets = action?.payload?.filteredPets

                if (filteredValue === "Adoption") {
                    state.filteredByAdoption = filteredPets
                }

                else if (filteredValue === "Lost") {
                    state.filteredByMissing = filteredPets
                }

                else if (filteredValue === "Found") {
                    state.filteredByFound = filteredPets
                }

                else if (filteredBy === "canReceiveFunds") {
                    state.filteredByCanReceiveFunds = filteredPets
                }

                else if (filteredValue === "Cat") {
                    state.filteredByCat = filteredPets
                }

                else if (filteredValue === "Dog") {
                    state.filteredByDog = filteredPets
                }

                else if (filteredValue === "Guinea Pig") {
                    state.filteredByGuineaPig = filteredPets
                }

            })
            .addCase(filterActions.setFilteredOrganizations, (state, action) => {

                if (action.payload?.filteredBy === "canReceiveFunds") {
                    state.giftableOrganizations = action.payload.organizations
                } else if (!action.payload?.filteredBy) {
                    state.guardians = action.payload.organizations
                }
            })
    }
})

export const cacheActions = cacheSlice.actions

const cacheReducers = cacheSlice.reducer

export default cacheReducers