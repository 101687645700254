import { createSlice } from "@reduxjs/toolkit"
import { authActions } from "./authSlice"

const initialState = {
    topNotification: "",
    showReviewAccountModal: false
}

const notificationSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {
        setTopNotification: (state, action) => {
            state.topNotification = action.payload
        },
        resetTopNotification: (state) => {
            state.topNotification = ""
        },
        cleanError: () => {
            return initialState
        },
        showReviewAccountModal: (state) => {
            state.showReviewAccountModal = !state.showReviewAccountModal
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(authActions.loginFail, (state, action) => {
                state.topNotification = action.payload
            })
            .addCase(authActions.loginUserSuccess, () => {
                return initialState
            })
            .addCase(authActions.updateUserInfo, () => {
                return initialState
            })
            .addCase(authActions.userLogout, () => {
                return initialState
            })
    }
})

const notificationReducers = notificationSlice.reducer

export const notificationActions = notificationSlice.actions

export default notificationReducers