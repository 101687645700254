// If adding or deleting species, please update "AddPetScreen"

const species = [
    "Other",
    "Bird",
    "Cat",
    "Chicken",
    "Dog",
    "Donkey",
    "Duck",
    "Fish",
    "Guinea Pig",
    "Hamster",
    "Horse",
    "Pig",
    "Rabbit",
    "Turtle",
]

export default species

