import { createSlice } from "@reduxjs/toolkit";
import { authActions } from "./authSlice";
import { organizationActions } from "./organizationSlice";

const initialState = {
    user: {},
    pets: [],
    shelters: [],
    favoritePets: [],
    following: [],
    isLoggedIn: false,
    chats: [],
    postponedIdVerification: false,
    allMultiSelectorValues: [],
    reason: [],
    otherDeletionReason: "",
    notifications: [],
}

const accountSlice = createSlice({
    name: "account",
    initialState,
    reducers: {
        updateUsersProfile: (state, action) => {
            state.user.name = action.payload
        },

        setUsersOrganizations(state, action) {
            state.shelters = action.payload
        },
        setUsersPets(state, action) {
            state.pets = action.payload
        },
        toggleFollow: (state, action) => {

            const existingIndex = state.following.findIndex(id => id === action.payload.profileId)

            if (existingIndex >= 0) {
                state.following.splice(existingIndex, 1)
            } else {
                state.following.push(action.payload.profileId)
            }

        },
        toggleFavorite: (state, action) => {

            const existingIndex = state.favoritePets.findIndex(id => id === action.payload.id)

            if (existingIndex >= 0) {
                state.favoritePets.splice(existingIndex, 1)
            } else {
                state.favoritePets.push(action.payload.id)
            }

        },
        updateMultipleValues: (state, action) => {

            // CREATE A COPY OF THE ARRAY WITH ALL SELECTED ITEMS
            // RIGHT NOW THIS ARRAY ONLY HAS REASON (TO DELETE) ITEMS
            // IN THE FUTURE, IT MAY HAVE OTHER KINDS OF VALUES
            const updatedSelectedItems = [...state.allMultiSelectorValues]

            // CREATE A COPY OF SPECIFIC PROP. e.g. REASON
            const updatedProp = [...state[action.payload.prop]]

            // FIND THE INDEX OF VALUE INSIDE THE ARRAY allMultiSelectorValues
            let existingIndexGlobal = updatedSelectedItems.findIndex(item => item === action.payload.value)

            // FIND THE INDEX OF VALUE INSIDE SPECIFIC PROP. e.g. REASON
            let existingIndexInProp = updatedProp.findIndex(item => item === action.payload.value)

            // IF ITEM IS ALREADY INSIDE allMultiSelectorValues ARRAY, DELETE ITEM
            if (existingIndexGlobal >= 0) {
                updatedSelectedItems.splice(existingIndexGlobal, 1)
                updatedProp.splice(existingIndexInProp, 1)

                state.allMultiSelectorValues = updatedSelectedItems
                state[action.payload.prop] = updatedProp
            }

            // OTHERWISE, ADD ITEM
            else {
                state[action.payload.prop] = updatedProp.concat(action.payload.value)
                state.allMultiSelectorValues = updatedSelectedItems.concat(action.payload.value)
            }
        },
        updateAccount: (state, action) => {
            state[action.payload.prop] = action.payload.value
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(authActions.loginUserSuccess, (state, action) => {

                state.user = { ...state.user, ...action.payload }  // BECAUSE USER CAN HAVE PUBLIC AND PRIVATE, INFO OBJECT HAVE TO COMBINE THEM
                state.following = action.payload?.following || []
                state.favoritePets = action.payload?.favorites || []
                state.isLoggedIn = true
            })
            .addCase(authActions.userLogout, () => {
                return initialState
            })
            .addCase(organizationActions.onOrganizationCreation, (state, action) => {
                state.shelters = [action.payload, ...state.shelters]
            })
            .addCase(organizationActions.onOrganizationDeletion, (state, action) => {

                const indexShelterToBeDeleted = state.shelters.findIndex(shelter => shelter.id === action.payload)

                if (indexShelterToBeDeleted >= 0) {
                    state.shelters.splice(indexShelterToBeDeleted, 1)
                }

                const newPetsArray = state.pets.filter(pet => pet.guardian.id !== action.payload)

                state.pets = newPetsArray
            })
    }
})

export const accountActions = accountSlice.actions

const accountReducers = accountSlice.reducer

export default accountReducers