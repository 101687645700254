import { blockTimeInSeconds } from "../../constants/variables"

import showErrorMessage from "../alerts/showErrorMessage"
import getDataFromStorage from "../storage/getDataFromStorage"
import saveDataToStorage from "../storage/saveDataToStorage"

const canUserLogin = () => {

    // CHECK IF USER WAS TEMPORARY BLOCKED FOR TRYING TO LOG IN WITH
    // A WRONG PASSWORD FOR THE limitOfLoginAttemptsPerSession.
    const blockExpiration = getDataFromStorage("blockExpiration")
    const didBlockExpire = blockExpiration < Date.now()
    
    // CLEAN LOCAL STORAGE WHEN THE BLOCK TIME IS UP
    if (didBlockExpire && blockExpiration > 0) {
        saveDataToStorage({ key: "blockExpiration", value: 0 })
    }
    
    else if (!didBlockExpire) {

        const message = `This device was blocked for ${blockTimeInSeconds/1000/60} minutes. Try again later.`
        showErrorMessage(message)
        return false
    }

    return true
}

export default canUserLogin