const getSerializableDates = (documentSnapshot, singleValue) => {

    if (singleValue) {
        const milliseconds = singleValue.toMillis()
        return milliseconds
    }

    const {
        lastModified: nonSerializableLastModified,
        createdAt: nonSerializableCreatedAt,
        verifiedAt: nonSerializableVerifiedAt } = documentSnapshot.data()

    const lastModified = !!nonSerializableLastModified ? nonSerializableLastModified?.toMillis() : ""
    const createdAt = !!nonSerializableCreatedAt ? nonSerializableCreatedAt?.toMillis() : ""
    const verifiedAt = !!nonSerializableVerifiedAt ? nonSerializableVerifiedAt?.toMillis() : 0

    return {
        lastModified,
        createdAt,
        verifiedAt
    }
}

export default getSerializableDates