import { createSlice } from "@reduxjs/toolkit"

import species from "../../data/species"
import { organizationActions } from "./organizationSlice"

const initialState = {
    availableSpecies: species,

    allPets: [], // do we really need this prop, since we already have all filtered pets on cache?
    selectedFavoritePet: {},
    deepLinkingPet: {},
    shouldCleanPetIdParam: false, // CHECK IF THIS PROP IS NEEDED ON THE WEB

    recentAddedPets: [],
    lastAvailSeen: {},

    missingPets: [],
    missingPetsForTab: null,// ONLY USE FOR MISSING TOP TAB - CHECK IF THIS PROP IS NEEDED ON THE WEB
    lastMissingSeen: {},

    foundPets: [],
    lastFoundPet: {},

    customCaption: "",
    hasReachedAvailEnd: null,
    hasReachedMissingEnd: null,
    shouldUseChainedQuery: false,

    loadedHome: false
}

const allPetsSlice = createSlice({
    name: "allPets",
    initialState,
    reducers: {

        setHomePagePets(state, action) {

            state.missingPets = action?.payload?.missingPets
            state.foundPets = action?.payload?.foundPets

            state.recentAddedPets = action?.payload?.recentAddedPets
            state.availableDogs = action?.payload?.availableDogs
            state.availableCats = action?.payload?.availableCats
            state.availableGuineaPigs = action?.payload?.availableGuineaPigs
            state.giftablePets = action?.payload?.giftablePets
            state.loadedHome = true
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(organizationActions.onOrganizationDeletion, (state, action) => {

                state.recentAddedPets = state.recentAddedPets.filter(pet => pet.guardian.id !== action.payload)
                state.availableDogs = state.availableDogs.filter(pet => pet.guardian.id !== action.payload)
                state.availableCats = state.availableCats.filter(pet => pet.guardian.id !== action.payload)
                state.availableGuineaPigs = state.availableGuineaPigs.filter(pet => pet.guardian.id !== action.payload)
                state.giftablePets = state.giftablePets.filter(pet => pet.guardian.id !== action.payload)

            })
    }
})

const allPetsReducers = allPetsSlice.reducer

export const allPetsActions = allPetsSlice.actions

export default allPetsReducers